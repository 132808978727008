const styles = {
  global: (props) => ({
    body: {
      bg: '#F6F8FF',
      lineHeight: 'base',
    },
  }),
};

export default styles;
