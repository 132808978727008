exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-edit-documents-tsx": () => import("./../../../src/pages/edit-documents.tsx" /* webpackChunkName: "component---src-pages-edit-documents-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-informasjon-tsx": () => import("./../../../src/pages/informasjon.tsx" /* webpackChunkName: "component---src-pages-informasjon-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-min-side-tsx": () => import("./../../../src/pages/min-side.tsx" /* webpackChunkName: "component---src-pages-min-side-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-user-admin-tsx": () => import("./../../../src/pages/user-admin.tsx" /* webpackChunkName: "component---src-pages-user-admin-tsx" */),
  "component---src-templates-blog-archive-template-tsx": () => import("./../../../src/templates/blog-archive-template.tsx" /* webpackChunkName: "component---src-templates-blog-archive-template-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page-template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */)
}

